body {
  margin: 0;
 /*  padding: 25px; */
 /*  font-family: sans-serif; */
  background-color: #F8F8F8;
}

input[type="time"]::-webkit-datetime-edit {
  opacity: 1; /* Make sure the input is visible */
}

.bgTexture {
background-color: #F0F0F0;

background: url(/static/media/podium.633e5d26.svg) no-repeat center center fixed;
background-size: cover;
}
.buttonAddMultipleTeams {
    border: 2px solid #509CF5 !important;
    background-color: transparent !important;
  }

.buttonAddMultipleTeams:hover {
  border: 2px solid #0d74eb !important;
}

.buttonAddMultipleTeams {
  color: #509CF5;
}

.buttonAddMultipleTeams:hover {
    color: #0d74eb;
}

.buttonAddMultipleTeams .mdGroupAdd {
  color: #509CF5;
}

.buttonAddMultipleTeams:hover .mdGroupAdd {
    color: #0d74eb;
}

.react-switch {
vertical-align: middle
}
@keyframes blink {
    0% {
      background-color: rgba(255,255,255, 1);
     }
    50% {
      background-color: #21f37e;
      color: '#FFF';
        }
    100% {
      background-color: rgba(255,255,255, 1);
       }
      }
    
    
      .flash {
    
        border-radius: 5px;
        transition:all 0.2s ease-in-out;
        /* Firefox */
        /* Webkit */
        /* IE */
        animation:blink normal 0.5s infinite ease-in-out;
        /* Opera */
      }

      .noFlash {
        background-color: #F8F8F8;
      }

body {
  margin: 0;
 /*  padding: 25px; */
 /*  font-family: sans-serif; */
  background-color: #F8F8F8;
}

input[type="time"]::-webkit-datetime-edit {
  opacity: 1; /* Make sure the input is visible */
}

.bgTexture {
background-color: #F0F0F0;

background: url("./img/podium.svg") no-repeat center center fixed;

-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}
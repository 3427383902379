@keyframes blink {
    0% {
      background-color: rgba(255,255,255, 1);
     }
    50% {
      background-color: #21f37e;
      color: '#FFF';
        }
    100% {
      background-color: rgba(255,255,255, 1);
       }
      }
@-webkit-keyframes blink {
    0% {
      background-color: rgba(255,255,255, 1);
       }
    50% {    
      background-color: #21f37e;
      color: '#FFF';
        }
        100% {
       background-color: rgba(255,255,255, 1);
        }
      }
    
    
      .flash {
    
        border-radius: 5px;
        -moz-transition:all 0.2s ease-in-out;
        -webkit-transition:all 0.2s ease-in-out;
        -o-transition:all 0.2s ease-in-out;
        -ms-transition:all 0.2s ease-in-out;
        transition:all 0.2s ease-in-out;
        -moz-animation:blink normal 0.5s infinite ease-in-out;
        /* Firefox */
        -webkit-animation:blink normal 0.5s infinite ease-in-out;
        /* Webkit */
        -ms-animation:blink normal 0.5s infinite ease-in-out;
        /* IE */
        animation:blink normal 0.5s infinite ease-in-out;
        /* Opera */
      }

      .noFlash {
        background-color: #F8F8F8;
      }
.buttonAddMultipleTeams {
    border: 2px solid #509CF5 !important;
    background-color: transparent !important;
  }

.buttonAddMultipleTeams:hover {
  border: 2px solid #0d74eb !important;
}

.buttonAddMultipleTeams {
  color: #509CF5;
}

.buttonAddMultipleTeams:hover {
    color: #0d74eb;
}

.buttonAddMultipleTeams .mdGroupAdd {
  color: #509CF5;
}

.buttonAddMultipleTeams:hover .mdGroupAdd {
    color: #0d74eb;
}

.react-switch {
vertical-align: middle
}